import React, { useState, useEffect } from 'react';

const VideoBanner = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  // Handle video loading errors
  const handleVideoError = (e) => {
    console.error('Error loading video:', e);
  };

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {/* Video container */}
      <div className="absolute inset-0">
        <video
          autoPlay
          muted={isMuted}
          loop
          playsInline
          onError={handleVideoError}
          className="w-full h-full object-cover"
        >
          <source 
            src="test.mp4"
            type="video/mp4" 
          />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Controls overlay */}
      <div className="absolute bottom-8 right-8 z-20 flex gap-4">
        <button
          onClick={() => {
            const video = document.querySelector('video');
            if (isPlaying) {
              video.pause();
            } else {
              video.play();
            }
            setIsPlaying(!isPlaying);
          }}
          className="bg-white/20 backdrop-blur-sm text-white px-4 py-2 rounded-full hover:bg-white/30 transition-colors"
        >
          {isPlaying ? 'Pause' : 'Play'}
        </button>
        
        <button
          onClick={() => setIsMuted(!isMuted)}
          className="bg-white/20 backdrop-blur-sm text-white px-4 py-2 rounded-full hover:bg-white/30 transition-colors"
        >
          {isMuted ? 'Unmute' : 'Mute'}
        </button>
      </div>

 
    </div>
  );
};

export default VideoBanner;